.new-post {
  padding-top: 10px;
  .ant-form-item {
    margin-bottom: 8px !important;

    .ant-form-item-label {
      padding-bottom: 4px !important;
    }
  }
}
