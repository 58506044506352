.deposit-container {
  padding-top: 20px;

  .description {
    font-size: 18px;
  }

  .note {
    font-style: italic;
    color: red;
  }
}
