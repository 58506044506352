.box-chat-info,
.box-room-chat-list {
  padding: 8px;
  cursor: pointer;
  border-radius: 12px;

  .item-chat-info {
    padding-left: 8px;
  }
}

.is-room-chat-selected {
  background-color: #ebf5ff;
}

.box-room-chat-list:hover {
  background-color: #ebf5ff;
}

.box-chat-info:hover {
  background-color: var(--shadow-2);
}

.box-chat-info .ant-col {
  font-size: 18px;
  font-weight: bold;
}
