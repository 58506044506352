// styles.scss

.bargain-container {
  .bargain_content {
    border: 1px solid black;
    border-radius: 16px;
    padding: 8px;

    .image_re {
      width: 100%;
      height: 200px;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 10px 0 0 10px;
    }

    .info_bargain {
      .text_title {
        font-family: "Inter";
        font-size: 18px;
        color: rgb(0, 0, 0);
      }
      .text_title:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      .text-shadow {
        font-family: Inter;
        font-weight: 400;
        font-size: 16px;
        color: black;
      }

      .info-item {
        svg {
          font-size: 18px;
          padding-right: 4px;
          color: var(--gray);
        }
        label {
          padding-right: 4px;
          color: var(--gray);
        }
      }
    }

    .button-group {
      padding-top: 10px;

      .custom-button {
        font-family: "Inter";
        font-weight: bold;
        width: 100%;
        border-color: none;

        &.cancel {
          border-color: #f83838;
          color: #f83838;
          background-color: transparent;
        }

        &.watch-contract {
          background-color: transparent;
        }

        &.chat {
          background-color: transparent;
        }
      }
    }
  }
}
