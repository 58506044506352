.msg {
  .msg-owner,
  .msg-partner {
    font-size: 18px;
    padding: 8px 12px;
    border-radius: 16px;
    max-width: 40vw;
    word-wrap: break-word;
    white-space: -webkit-pre-wrap;
    word-break: break-all;
    white-space: normal;
  }

  .msg-owner {
    background-color: var(--light-blue);
    color: var(--dark-blue);
  }

  .msg-partner {
    background-color: var(--dark-blue);
    color: white;
  }

  .message-image {
    max-width: 300px;
  }
  .hidden {
    display: none;
  }
}
