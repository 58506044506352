.card-house_home {
  position: relative;
  display: flex;

  .image-card_house_home {
    border-radius: 16px;
    width: calc((100vw - 2 * var(--margin-side)) / 4 - 15px) !important;
    height: calc(
      ((100vw - 2 * var(--margin-side)) / 4 - 15px) * 2 / 3
    ) !important;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
  }

  label {
    text-shadow: 1px 1px 2px black;
  }
  .house-info_card {
    color: var(--color-text-light);
    width: calc((100vw - 2 * var(--margin-side)) / 4 - 15px) !important;
    height: calc(
      ((100vw - 2 * var(--margin-side)) / 4 - 15px) * 2 / 3
    ) !important;
    .name_house:hover {
      text-decoration: underline;
    }
    display: flex;
    align-items: end;
    padding-left: 4px;
    padding-bottom: 12px;
  }

  .open-link-house {
    font-size: 30px;
    color: var(--color-text-light);
    cursor: pointer;
    text-shadow: 1px 1px 2px black;
  }

  .open-link-house:hover {
    color: rgb(85, 184, 250) !important;
  }
}
