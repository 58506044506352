.room-chat {
  height: calc(100vh - var(--height-navbar) - 24px);
  overflow: hidden;

  .text-bold-18 {
    font-size: 18px;
    font-weight: bold;
  }

  .chat-list,
  .chat-info {
    background-color: white !important;
    border: 1px solid var(--shadow-2);
    padding: 8px 8px;
    overflow: auto;

    .title-sidebar {
      font-size: 23px;
      font-weight: bold;
      padding: 20px 12px;
    }
  }

  .content-room-chat {
    background-color: white;

    .msg-header {
      justify-content: space-between;
      box-shadow: 0 0 4px var(--shadow-2);
      align-items: center;
      padding: 12px 16px;
      min-height: 64px;
      flex-grow: 0;

      .wrap-icon {
        width: 36px;
        height: 36px;
        display: flex;
        border-radius: 18px;
        justify-content: center;
        align-items: center;
      }

      .wrap-icon:hover {
        cursor: pointer;
        background-color: var(--shadow-2);
      }

      .icon-edit-name-room {
        padding-left: 8px;
        cursor: pointer;
      }

      .icon-edit-name-room:hover {
        color: orange;
      }
    }

    .msg-body {
      flex: 1 1 auto;
      overflow-y: auto;
      padding: 20px 16px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .input-message {
      background-color: white;
      min-height: 64px;
      display: inline-table;
      align-items: center;
      justify-content: center;
      padding: 0px 20px;
      flex-grow: 0;

      .wrap-input-message {
        background-color: #f7f9fb;
        padding: 8px 20px;
        align-items: center;
        border-radius: 12px;

        .ant-input {
          border: none !important;
          outline: none !important;
          background-color: transparent !important;
          box-shadow: none !important;
        }

        .icon-input {
          font-size: 18px;
          cursor: pointer;
        }

        .icon-input:hover {
          font-size: 20px;
        }

        #input-image-message {
          display: none;
        }
      }
    }
  }
}
