.search-layout {
  height: calc(100vh - var(--height-navbar) - 24px);
  overflow: hidden;

  .search-container {
    background-color: white !important;
    border: 1px solid var(--shadow-2);
    padding: 8px 8px;
    overflow: auto;
  }

  .result {
    .result-container {
      flex: 1 1 auto;
      padding: 0 20px;
      overflow-y: scroll;
    }
  }

  .ant-form-item {
    margin-bottom: 4px;
  }

  #order-form .ant-form-item {
    margin-bottom: 0px;
  }

  .wrap-input-search {
    margin-top: 12px;

    .ant-form-item {
      margin-bottom: 0px;
    }
    .ant-btn {
      min-width: 100%;
    }
  }
}
